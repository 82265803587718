import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
import Home from '../views/home.vue'
// import UAParser from 'ua-parser-js';
// const uaParser = new UAParser();
// const deviceInfo = uaParser.getResult();

// const isMobileDevice = deviceInfo.device.type === 'mobile';
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'en-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp|GB WhatsApp APK Download V18.30 (Anti-Ban)`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GB WhatsApp is a popular modified app of the regular WhatsApp. This site introduces the extra features of GB WhatsApp APK and also provides GB WhatsApp APK file to download. `,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/'
				}
			]
		},
		component: Home,
	},
	{
		path: '/new',
		name: 'Homenew',
		meta: {
			appname: 'GBWhatsApp',
		},
		component: Home,
	},
	{
		path: '/whatsapp-mods',
		name: 'mod',
		meta: {
			appname: 'GBWhatsApp',
			title: `WhatsApp Mods APK Download Anti-Ban Updates 2025`,
			keywords: 'WhatsApp Mods,WhatsApp Mods APK,WhatsApp Mods APK Download',
			description: `Download all WhatsApp Mods with the latest official and anti-ban versions in 2025.Popular mods include FMWhatsApp, GBWhatsApp,YoWhatsApp,JTWhatsApp,WhatsApp Plus and more.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/whatsapp-mods/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'whatsapp-mods',
				},
			],
		},
		component: () => import('@/views/mod.vue'),
	},
	{
		path: '/fmwhatsapp-apk-download',
		name: 'fmMod',
		meta: {
			appname: 'GBWhatsApp',
			title: `FM WhatsApp APK v10.10 download Latest Version 2025（Anti-Ban）`,
			keywords: 'FM WhatsApp,FM WhatsApp APK,FM WhatsApp download',
			description: `Download the Latest Version FMWhatsApp APK for Android and enjoy the anti-ban version with enhanced privacy and advanced customization options.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/fmwhatsapp-apk-download/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'fmwhatsapp-apk',
				},
			],
		},
		component: () => import('@/views/fmMod.vue'),
	},
	{
		path: '/jtwhatsapp-apk-download',
		name: 'jtMod',
		meta: {
			appname: 'GBWhatsApp',
			title: `Download JT WhatsApp 2025 – Latest Version Updated`,
			keywords: ' jt whatsapp, jt whatsapp, download jt whatsapp, télécharger jt WhatsApp',
			description: `Download JT WhatsApp and unlock exclusive features like enhanced privacy, customization, anti-delete messages, and DND mode. Download the latest version now!`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/jtwhatsapp-apk-download/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'jtwhatsapp-apk',
				},
			],
		},
		component: () => import('@/views/jtMod.vue'),
	},
	{
		path: '/whatsapp-plus-apk-download',
		name: 'plusMod',
		meta: {
			appname: 'GBWhatsApp',
			title: `Download WhatsApp Plus APK with Anti-Ban Protection [Premium]`,
			keywords: 'whatsapp plus, whatsapp plus anti ban, whatsapp plus download, whatsapp plus update',
			description: `Download the latest WhatsApp Plus APK for Android and unlock unlimited features, enhanced privacy, and premium customization. Install now for a next-level messaging experience! `,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/whatsapp-plus-apk-download/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'whatsapp-plus-apk',
				},
			],
		},
		component: () => import('@/views/plusMod.vue'),
	},
	{
		path: '/ogwhatsapp-apk-download',
		name: 'ogMod',
		meta: {
			appname: 'GBWhatsApp',
			title: `OG WhatsApp|Download OG WhatsApp APK & Enjoy Unlocked Features`,
			keywords: 'ogwhatsapp, ogwhatsapp apk, og whatsapp pro',
			description: `Download OG WhatsApp APK for enhanced privacy, dual accounts, and customization. Get the latest version securely and enjoy advanced features on Android! `,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/ogwhatsapp-apk-download/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'og-whatsapp-apk',
				},
			],
		},
		component: () => import('@/views/ogMod.vue'),
	},
	{
		path: '/yowhatsapp-apk-download',
		name: 'yoMod',
		meta: {
			appname: 'GBWhatsApp',
			title: `Download YO WhatsApp APK v10.10 New Version 2025 `,
			keywords: 'YOWhatsApp,YO WhatsApp APK,YO WhatsApp download',
			description: `Download YO WhatsApp APK v10.10 New Version with Anti-Ban Protection. Get the latest features, enhanced privacy options, and improved customization in this updated version of YO WhatsApp. `,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/yowhatsapp-apk-download/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'yowhatsapp-apk',
				},
			],
		},
		component: () => import('@/views/yoMod.vue'),
	},
	{
		path: '/downloadpage',
		name: 'download',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Download|Download GB WhatsApp Latest Version 2025`,
			keywords: 'gbwhatsapp download, gb whatsapp download, download gbwhatsapp, gbwhatsapp apk download',
			description: `Here we support GB WhatsApp(Pro) APK download and keep updating GBWhatsApp new version instantly.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/downloadpage/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'Download',
				},
			],
		},
		component: () => import('@/views/download.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp | Why do people prefer GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/blogs/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'Blogs',
				},
			],
		},
		component: () => import('@/views/blogs.vue'),
	},
	{
		path: '/category/gb-whatsapp-update',
		name: 'cate-update',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp Update & Changelog`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `This page covers GB WhatsApp latest updates, new features, bug fixes, and version changes.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/category/gb-whatsapp-update/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'GB WhatsApp Update',
				},
			],
		},
		component: () => import('@/views/cate-update.vue'),
	},
	{
		path: '/category/tips-and-resources',
		name: 'cate-tip',
		meta: {
			appname: 'GBWhatsApp',
			title: `Tips and Resources`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `This category provides usage tips, security advice, whatsapp hot topcs and tips.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/category/tips-and-resources/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'Tips and Resources',
				},
			],
		},
		component: () => import('@/views/cate-tip.vue'),
	},
	{
		path: '/category/troubleshooting',
		name: 'cate-troubleshooting',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Troubleshooting`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `This category contains troubleshooting guides to help users use GB WhatsApp successfully.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/category/troubleshooting/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'GBWhatsApp Troubleshooting',
				},
			],
		},
		component: () => import('@/views/cate-troubleshooting.vue'),
	},
	{
		path: '/blog-1',
		name: 'blog1',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp | How to use same WhatsApp Account on Different phone?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/blog-1/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: 'How to use same WhatsApp Account on Different phone?',
				},
			],
		},
		component: () => import('@/views/blog1.vue'),
	},
	{
		path: '/blog-2',
		name: 'blog2',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp | GB WhatsApp Data Recovery Way`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/blog-2/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: 'GB WhatsApp Data Recovery Way',
				},
			],
		},
		component: () => import('@/views/blog2.vue'),
	},
	{
		path: '/blogs/GBWhatsApp-Channels',
		name: 'blog3',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp | WhatsApp Channels｜How to create a WhatsApp Channel`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/blogs/GBWhatsApp-Channels/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: 'GBWhatsApp Channels',
				},
			],
		},
		component: () => import('@/views/blog3.vue'),
	},
	{
		path: '/blog-4',
		name: 'blog4',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp｜Is GB WhatsApp safe?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/blog-4/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: 'All Your Questions About GB WhatsApp',
				},
			],
		},
		component: () => import('@/views/blog4.vue'),
	},
	{
		path: '/blog-5',
		name: 'blog5',
		meta: {
			appname: 'GBWhatsApp',
			title: `The 10 Best Apps for Spying someone else's WhatsApp`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/blog-5/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "The 10 Best Apps for Spying someone else's WhatsApp",
				},
			],
		},
		component: () => import('@/views/blog5.vue'),
	},
	{
		path: '/fix-you-need-official-whatsapp',
		name: 'blog6',
		meta: {
			appname: 'GBWhatsApp',
			title: `Fix You Need The Official WhatsApp to Use This Account`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `In this blog, we provide steps to resolve the common issue of "You Need The Official WhatsApp to Use This Account" error message.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/fix-you-need-official-whatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "Error: You Need The Official WhatsApp to Use This Account",
				},
			],
		},
		component: () => import('@/views/blog6.vue'),
	},
	{
		path: '/how-to-hide-online-status-on-gb-whatsapp',
		name: 'blog7',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Hide GB Whatsapp Online Status While Chatting`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `To hide online status while chatting on GBWhatsApp, we introduced the detailed guide. More privacy options like hide blue ticks, disable last seen are introduced.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/how-to-hide-online-status-on-gb-whatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "How to Hide Online Status on GB Whatsapp",
				},
			],
		},
		component: () => import('@/views/blog7.vue'),
	},
	{
		path: '/gbwhatsapp-not-working',
		name: 'blog8',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Not Working: Reasons and Solutions`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Why can't your GBWhatsApp send or receive messages? Here are the possible reasons and solutions to fix it.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/gbwhatsapp-not-working/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "GBWhatsApp Not Working: Reasons and Solutions",
				},
			],
		},
		component: () => import('@/views/blog8.vue'),
	},
	{
		path: '/uninstall-gb-whatsapp',
		name: 'blog9',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Uninstall GB WhatsApp (Pro) From Local Storage 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `To remove GB WhatsApp from Android phone completely, you should backup data firstly and then delete whole data folder from local storage.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/uninstall-gb-whatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "How to Delete GB WhatsApp on Android Completely",
				},
			],
		},
		component: () => import('@/views/blog9.vue'),
	},
	{
		path: '/how-to-use-gb-whatsapp-without-being-banned',
		name: 'blog10',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Use GB WhatsApp Without Being Banned [Updated]`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GB WhatsApp faces ban issues, how to use GB WhatsApp without being banned? Here we provide practical suggestions for you.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/how-to-use-gb-whatsapp-without-being-banned/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "How to Use GB WhatsApp Without Being Banned",
				},
			],
		},
		component: () => import('@/views/blog10.vue'),
	},
	{
		path: '/lock-chat-in-gbwhatsapp',
		name: 'blog11',
		meta: {
			appname: 'GBWhatsApp',
			title: `Methods to Hide Chat in GB WhatsApp 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `How to lock or hide chats in GB WhatsApp? Here we introduced simple but useful steps to lock GB WhatsApp chats to protect your privacy.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/lock-chat-in-gbwhatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "Methods to Hide Chat in GB WhatsApp 2025",
				},
			],
		},
		component: () => import('@/views/blog11.vue'),
	},
	{
		path: '/gbwhatsapp-vs-fmwhatsapp',
		name: 'blog12',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp vs. FM WhatsApp: Key Differences & Features Compared`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Discover the key differences between GB WhatsApp and FM WhatsApp. Compare features and performance to choose the best WhatsApp mod for your needs.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/gbwhatsapp-vs-fmwhatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "GB WhatsApp vs. FM WhatsApp: Key Differences & Features Compared",
				},
			],
		},
		component: () => import('@/views/blog12.vue'),
	},
	{
		path: '/gbwhatsapp-vs-yowhatsapp',
		name: 'blog13',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp vs YO WhatsApp: Full Comparison 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Compare GB WhatsApp and YO WhatsApp in detail. Discover key features, pros & cons, and find out which modded app suits your needs best.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/gbwhatsapp-vs-yowhatsapp/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{ text: 'Blogs', to:'https://www.gbwhatsapp.chat/blogs/' },
				{
					text: "GB WhatsApp vs YO WhatsApp: Full Comparison 2025",
				},
			],
		},
		component: () => import('@/views/blog13.vue'),
	},
	{
		path: '/privacy',
		name: 'privacy',
		meta: {
			appname: 'GBWhatsApp',
			title: ` Privacy Policy-gbwhatsapp.chat`,
			keywords: 'GBWhatsApp privacy policy',
			description: `Review the privacy policy for GBWhatsApp to understand how your data is handled. We are committed to protecting your privacy and ensuring secure usage of our WhatsApp mod features.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/privacy/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'Privacy Policy',
				},
			],
		},
		component: () => import('@/views/privacy.vue'),
	},
	{
		path: '/about-us',
		name: 'about',
		meta: {
			appname: 'GBWhatsApp',
			title: ` About Us-gbwhatsapp.chat`,
			keywords: ' About GBWhatsApp',
			description: ` Learn more about GBWhatsApp, the innovative WhatsApp mod that brings enhanced privacy, customization, and additional features. Discover our mission to provide users with the best messaging experience.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/about-us/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'About Us',
				},
			],
		},
		component: () => import('@/views/about.vue'),
	},
	{
		path: '/contact-us',
		name: 'contact',
		meta: {
			appname: 'GBWhatsApp',
			title: `Contact Us - GBWhatsApp Support & Inquiries`,
			keywords: 'GBWhatsApp contact, GBWhatsApp support',
			description: `Get in touch with the GBWhatsApp team for support, inquiries, or feedback. Our contact page provides you with all the information you need to reach us quickly.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/contact-us/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'Contact Us',
				},
			],
		},
		component: () => import('@/views/contact.vue'),
	},
	{
		path: '/disclaimer',
		name: 'disclaimer',
		meta: {
			appname: 'GBWhatsApp',
			title: `Disclaimer-gbwhatsapp.chat`,
			keywords: 'GBWhatsApp disclaimer',
			description: `Read the official disclaimer for GBWhatsApp. Understand the terms of use, legal responsibilities, and the limitations of the app. `,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/disclaimer/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'Disclaimer',
				},
			],
		},
		component: () => import('@/views/disclaimer.vue'),
	},
	{
		path: '/gb-whatsapp-for-pc',
		name: 'pc',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp Download For PC (Windows11,10,9,8,7)`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Are you seeking for GB WhatsApp for PC? This page provides detailed methods to help you use GB WhatsApp on Windows with a third-party app.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/gb-whatsapp-for-pc/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'GB WhatsApp For PC',
				},
			],
		},
		component: () => import('@/views/pc.vue'),
	},
	{
		path: '/gbwhatsapp-login-problem',
		name: 'login-problem',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp Login Problem Solved–Easy Fixes 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Facing login issues on GB WhatsApp? Learn how to fix GBWhatsApp login problems and get expert solutions. Read now!`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/gbwhatsapp-login-problem/'
				}
			],
			breadcrumb: [
				{ text: 'GB WhatsApp', to:'https://www.gbwhatsapp.chat/' },
				{
					text: 'GB WhatsApp Login Problem',
				},
			],
		},
		component: () => import('@/views/login-problem.vue'),
	},
	{
		path: '/ar',
		name: 'ar-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK تنزيل الإصدار V18.30 2024 لشهر يونيو`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `واتساب الذهبي هو تطبيق رائع لأي شخص يريد تجربة واتساب أكثر تخصيصًا وغنية بالميزات. قم بتنزيل واتساب الذهبي للحصول على الإصدار الثابت.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/ar/'
				}
			]
		},
		component: () => import('@/views/ar.vue'),
	},
	{
		path: '/id',
		name: 'id-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK Unduh Versi V18.30 Juni 2024`,
			description: `GBWhatsApp Pro adalah aplikasi yang hebat untuk siapa saja yang menginginkan pengalaman WhatsApp yang lebih personal dan kaya fitur. Unduh GBWhatsApp untuk versi yang sudah diperbaiki.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/id/'
				}
			]
		},
		component: () => import('@/views/id.vue'),
	},
	{
		path: '/po',
		name: 'po-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK Download Versão V18.30 Junho 2024`,
			description: `GBWhatsApp Pro é um ótimo aplicativo para quem deseja uma experiência do WhatsApp mais personalizada e rica em recursos. Baixe o GBWhatsApp para a versão corrigida.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/po/'
				}
			]
		},
		component: () => import('@/views/po.vue'),
	},
	{
		path: '/tr',
		name: 'tr-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK İndir V18.30 2024 Haziran Sürümü`,
			description: `GBWhatsApp Pro, daha kişiselleştirilmiş ve özellik açısından zengin bir WhatsApp deneyimi isteyen herkes için harika bir uygulamadır. Düzeltme sürümü için GBWhatsApp'ı indirin.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/tr/'
				}
			]
		},
		component: () => import('@/views/tr.vue'),
	},
	{
		path: '/in',
		name: 'in-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK डाउनलोड V18.30 2024 जून संस्करण`,
			description: `GBWhatsApp Pro उन सभी के लिए एक शानदार ऐप है जो अधिक व्यक्तिगत और सुविधाओं से भरपूर WhatsApp अनुभव चाहते हैं। फिक्स्ड संस्करण के लिए GBWhatsApp डाउनलोड करें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/in/'
				}
			]
		},
		component: () => import('@/views/in.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://www.gbwhatsapp.chat/transfer/'
				}
			]
		},
		component: () => import('@/views/transfer.vue'),
	},
]

// export const constRoutesM = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: true,
// 			showTelegram: true,
// 		},
// 		// component: HomeM,
// 	},
// 	{
// 		path: '/download',
// 		name: 'download',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: false,
// 		},
// 		component: () => import('@/components/download.vue'),
// 	},
// ]

let routes = []
// if (isMobileDevice) {
// 	routes = constRoutesM
// }
// if (!isMobileDevice) {
// 	routes = constRoutesPc
// }

routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
